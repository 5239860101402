import * as  React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

import useIsClient from "../hooks/use-is-client";
import TrustpilotMicro from "./trustpilot/trustpilot-micro";
import ClickRevealDropdown from "./click-reveal-dropdown";

export default function CtaHero(props: any) {
  const images = getImage(props.advisorData?.photo2x?.localFile?.childImageSharp)
  const {isClient, key} = useIsClient();

  return (
    <div className="cta-card d-flex flex-column mt-3 mt-xl-0 mb-4 mb-xl-0">
      <div className="cta-card__person text-center">
        {isClient && <GatsbyImage
          className="advisor-list__img" image={images}
          alt={"Funeral Advisor - Fenix Funeral Directors"} loading="lazy"
        />}
      </div>
      <div className="cta-card__block text-center">
        <div className="cta-card__title text-white">Book meeting</div>
        <ClickRevealDropdown
          className="cta-card__button cta-card__hero text-center m-auto w-100"
          meetingTitle="Contact us today"
        />
      </div>
      <TrustpilotMicro
        theme='dark'
      />
    </div>
  );
}