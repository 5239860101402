import * as React from "react";

import CtaHero from "./cta-card-hero";
import {Link} from "gatsby";

export default function HeroContentHeading(props: any) {
  return (
    <div className="row no-gutters hero-content__heading align-items-top">
      {props.citeText && props.citeText.length != 0 ?
        <div className="d-none d-lg-block col-12 col-lg-2 hero-cite pt-2 pb-4 pb-lg-0 pr-lg-3">
          <span>{props.citeText}</span></div> : typeof props.citeText !== 'undefined' && props.citeText.length === 0 ?
          "" : ""}
      {props.headingTitle && <div
        className={props.headingTitle && props.citeText && props.citeText.length != 0 ? "col-12 col-lg-6 pr-lg-3 pl-lg-5" : props.headingTitle && typeof props.citeText !== 'undefined' && props.citeText.length === 0 ? "col-12 col-lg-6 pr-lg-4 pl-lg-0 d-flex flex-column justify-content-end" : props.headingTitle ? "col-12 col-lg-8 d-flex flex-column justify-content-end" : "col-12"}>
        {props.partnerLogo ? <img src={props.partnerLogo} alt={props.partnerTitle} width={'450'} height={'130'}/> : ``}
        <div className="hero-content__wrapper">
          {props.headingTitle ?
            <h1 className="hero-content__title" dangerouslySetInnerHTML={{__html: props.headingTitle}}></h1> : ``}
          {props.headingContent ? <div className="hero-content__subtitle">{props.headingContent.split('<br />').map(
            (it: any, i: any) => <div className='pb-0' key={'x' + i}>{it}</div>)}</div> : ``}
          {props.headingLocation ?
            <div className="hero-content__location pt-3">{props.headingLocation}<span className="hero-content__notice">(By appointment only)</span>
            </div> : ``}
          <div className="hero-content__price mt-2">
            {props.priceList ? <p className="mt-4 mb-3"><span
              className="price-title text-white">Price: {props.priceFrom ? props.priceFrom : ""}</span> <span
              className="price-color"> £ {props.price}</span></p> : ''}
            {props.priceList ? props.priceList : ""}
            {props.priceList ?
              <p className="pt-2 text-white mb-0">(Fenix covers many locations in the UK. Please be aware that regional
                pricing is in place).</p> : ""}
          </div>
        </div>
      </div>}
      {!props.citeText && !props.headingTitle ? <div className="col-12 col-lg-8"></div> : ''}
      {props.advisorData &&
        <div
          className={props.headingTitle && props.citeText && props.citeText.length != 0 ? "col-12 col-lg-4" : props.headingTitle && typeof props.citeText !== 'undefined' && props.citeText.length === 0 ? "col-12 col-lg-6 d-flex flex-column-reverse flex-md-column justify-content-end" : "col-12 col-lg-4 d-flex align-items-end"}>
          <CtaHero
            advisorData={props.advisorData}
          />
          {props.safeBadge && <div
            className="safe-badge--city text-deepblue bg-white px-3 pt-2 pb-2 d-flex justify-content-between no-gutters">
            <span className="">Fenix Funeral is working in partnership with Funeral Safe. Struggling
              with the cost of a funeral? Get help to spread funeral payments over time. <Link
                to="/guidance/funeral-financing/funeral-loans/" title="Funeral safe" className=""><u>Learn more</u></Link></span>
            <Link to="/guidance/funeral-financing/funeral-loans/" title="Funeral safe"><img
              src="/images/icon-safe.svg" alt="Safe logo" className=""/></Link>
          </div>}
        </div>}
    </div>
  )
}