import * as React from "react";

export default function TemplateHead(props: any) {
  return (
    <>
      <title>{props.title ? props.title : "Funeral home with Free Funeral Advice | Fenix Funeral Directors"}</title>
      <meta name="description"
            content={props.description ? props.description : "Arrange beautiful funeral from £995. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning."}/>
      <script type="application/ld+json" id="fenix-ld-json-main" dangerouslySetInnerHTML={{
        __html: props.ldjson ? props.ldjson : `
        {
          "@context": "https://schema.org",
          "@id": "https://fenixfuneral.co.uk/#webpage",
          "url": "https://fenixfuneral.co.uk/",
          "@type": "Organization",
          "name": "Fenix Funeral Ltd",
          "alternateName": "Fenix Funerals",
          "logo": "https://fenixfuneral.co.uk/images/Logo-Dark.svg",
          "image": "https://fenixfuneral.co.uk/images/Logo-Dark.svg",
          "telephone": "+46 10 888 73 67",
          "sameAs": [
            "https://www.facebook.com/fenixfunerals",
            "https://www.linkedin.com/company/fenix-begravning-uk/",
            "https://www.instagram.com/fenixbegravning/"
          ] 
        }
      `
      }}/>
      {props.ldjsonbreadcrumb && <script type="application/ld+json" id="fenix-ld-json-breadcrumb"
                                         dangerouslySetInnerHTML={{__html: props.ldjsonbreadcrumb}}/>}
      {props.ldjsonmore && <script type="application/ld+json" id="fenix-ld-json-more"
                                   dangerouslySetInnerHTML={{__html: props.ldjsonmore}}/>}
      {props.ldjsonmedia && <script type="application/ld+json" id="fenix-ld-json-media"
                                    dangerouslySetInnerHTML={{__html: props.ldjsonmedia}}/>}
        {props.ldjsonfaq && <script type="application/ld+json" id="fenix-ld-json-faq"
                                      dangerouslySetInnerHTML={{__html: props.ldjsonfaq}}/>}
      <link rel="shortlink"
            href={props.shortlink ? props.shortlink + (props.shortlink.substring(props.shortlink.length - 1) !== "/" ? "/" : "") : "https://fenixfuneral.co.uk/"}/>
      <link rel="alternate"
            href={props.alternate ? props.alternate + (props.alternate.substring(props.alternate.length - 1) !== "/" ? "/" : "") : "https://fenixfuneral.co.uk/"}/>
      <link rel="canonical"
            href={props.canonical ? props.canonical + (props.canonical.substring(props.canonical.length - 1) !== "/" ? "/" : "") : "https://fenixfuneral.co.uk/"}/>
      <meta charSet="UTF-8" content="FENIX FUNERAL"/>
      <meta name="application-name" content="FENIX FUNERAL"/>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, minimum-scale=1"/>
      <meta name="msapplication-TileColor" content="#FFFFFF"/>
      <meta name="msapplication-TileImage" content="https://fenixfuneral.co.uk/favicon/mstile-144x144.png"/>
      <meta name="msapplication-square70x70logo" content="https://fenixfuneral.co.uk/favicon/mstile-70x70.png"/>
      <meta name="msapplication-square150x150logo" content="https://fenixfuneral.co.uk/favicon/mstile-150x150.png"/>
      <meta name="msapplication-wide310x150logo" content="https://fenixfuneral.co.uk/favicon/mstile-310x150.png"/>
      <meta name="msapplication-square310x310logo" content="https://fenixfuneral.co.uk/favicon/mstile-310x310.png"/>
      {!props.noOpenGraph &&
        <meta property="og:url" content={props.og?.url ? props.og.url : "https://fenixfuneral.co.uk/"}/>}
      {!props.noOpenGraph && <meta property="og:type" content={props.og?.type ? props.og.type : "website"}/>}
      {!props.noOpenGraph && <meta property="og:title"
                                   content={props.og?.title ? props.og.title : "Funeral home with Free Funeral Advice | Fenix Funeral Directors"}/>}
      {!props.noOpenGraph && <meta property="og:description"
                                   content={props.og?.description ? props.og.description : "Arrange beautiful funeral from £995. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning."}/>}
      {!props.noOpenGraph && <meta property="og:image"
                                   content={props.og?.image ? props.og.image : "https://fenixfuneral.co.uk/images/Logo-Dark.svg"}/>}
      {!props.noOpenGraph && <meta property="og:site_name" content="Fenix Funeral Directors"/>}
      {props.business && props.business.contact_data && props.business.contact_data.street_address &&
        <meta property="business:contact_data:street_address" content={props.business.contact_data.street_address}/>}
      {props.business && props.business.contact_data && props.business.contact_data.locality &&
        <meta property="business:contact_data:locality" content={props.business.contact_data.locality}/>}
      {props.business && props.business.contact_data && props.business.contact_data.region &&
        <meta property="business:contact_data:region" content={props.business.contact_data.region}/>}
      {props.business && props.business.contact_data && props.business.contact_data.postal_code &&
        <meta property="business:contact_data:postal_code" content={props.business.contact_data.postal_code}/>}
      {props.business && props.business.contact_data && props.business.contact_data.country_name &&
        <meta property="business:contact_data:country_name" content={props.business.contact_data.country_name}/>}
      <meta property="fb:app_id" content="966242223397117"/>
      <meta property="facebook-domain-verification" content="hiavpevtm86kxvhvhvuglgzj65osw2"/>
      <link rel="dns-prefetch" href="//www.googleoptimize.com"/>
      <link rel="dns-prefetch" href="//www.googletagmanager.com"/>
      <link rel="dns-prefetch" href="//www.google-analytics.com"/>
      <link rel="dns-prefetch" href="//223986.tctm.co"/>
      <link rel="dns-prefetch" href="//widget.trustpilot.com"/>
      <link rel="icon" type="image/x-icon" href="https://fenixfuneral.co.uk/favicon/favicon.ico"/>
      <link rel="apple-touch-icon-precomposed" sizes="57x57"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-57x57.png"/>
      <link rel="apple-touch-icon-precomposed" sizes="114x114"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-114x114.png"/>
      <link rel="apple-touch-icon-precomposed" sizes="72x72"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-72x72.png"/>
      <link rel="apple-touch-icon-precomposed" sizes="144x144"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-144x144.png"/>
      <link rel="apple-touch-icon-precomposed" sizes="60x60"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-60x60.png"/>
      <link rel="apple-touch-icon-precomposed" sizes="120x120"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-120x120.png"/>
      <link rel="apple-touch-icon-precomposed" sizes="76x76"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-76x76.png"/>
      <link rel="apple-touch-icon-precomposed" sizes="152x152"
            href="https://fenixfuneral.co.uk/favicon/apple-touch-icon-152x152.png"/>
      <link rel="icon" type="image/png" sizes="196x196" href="https://fenixfuneral.co.uk/favicon/favicon-196x196.png"/>
      <link rel="icon" type="image/png" sizes="96x96" href="https://fenixfuneral.co.uk/favicon/favicon-96x96.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="https://fenixfuneral.co.uk/favicon/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="https://fenixfuneral.co.uk/favicon/favicon-16x16.png"/>
      <link rel="icon" type="image/png" sizes="128x128" href="https://fenixfuneral.co.uk/favicon/favicon-128.png"/>
      {props.children}
    </>
  )
}