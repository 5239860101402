import * as React from "react";

export default function TrustpilotCarousel(props: any) {
  return (
    <div className="reviews-container bg-lightgrey">
      <div className="reviews-wrapper">
        <h2 className="reviews-title text-center">{props.titleOurReviews ? props.titleOurReviews : "Our reviews"}</h2>
        <div
          className="reviews-subtitle text-center">{props.textOurReviews ? props.textOurReviews : "We are proud to help create memorable moments for our customers, here is what they have to say.."}</div>
        <div
          id="trustbox"
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="54ad5defc6454f065c28af8b"
          data-businessunit-id="5e66470294120700016c1a0f"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars="3,4,5"
          data-review-languages="en">
          <a href="https://uk.trustpilot.com/review/fenixfuneral.co.uk" target="_blank"
             rel="noopener">Trustpilot</a>
        </div>
      </div>
    </div>
  );
}
