import * as React from "react";
import {graphql, PageProps} from "gatsby"

import CleanDataParser from "../helpers/editorjs-parser";
import TemplateHead from "../components/template-head";
import Layout from "../components/layout";
import {useCityList} from "../hooks/use-city-list";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import Block4text8image from "../components/blocks/block-4text-8image";
import Timeline from "../components/timeline";
import BenefitsSlider from "../components/slider/benefits-slider";
import Block8Right from "../components/blocks/block-8right";
import BlockMap from "../components/blocks/block-map";
import Section from "../components/section";
import MapSingle from "../components/map/single-fixed";
import AdvisorRandom from "../components/advisor-random";
import Popout from "../components/city-select";

import "../styles/page-about.scss";

type DataProps = {
  strapiAdvisor: any,
  advisorBg: any,
  aboutPageData: any,
}

export default function AboutUs({data: {strapiAdvisor, advisorBg, aboutPageData}}: PageProps<DataProps>) {

  const advisor = strapiAdvisor

  const pageTextAboutFenix = (
    <div>
      {aboutPageData.textAboutFenix?.data?.textAboutFenix ? (
        JSON.parse(aboutPageData.textAboutFenix?.data?.textAboutFenix).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const pageTextMap = (
    <div>
      {aboutPageData.textMap?.data?.textMap ? (
        JSON.parse(aboutPageData.textMap?.data?.textMap).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const pageTextLineCurved = (
    <div>
      {aboutPageData.textLineCurved?.data?.textLineCurved ? (
        JSON.parse(aboutPageData.textLineCurved?.data?.textLineCurved).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--about pl-4 pr-4"
        bgImageClassName="hero-image hero-image--about"
        contentClassName="hero-content hero-content--about"
        imageGlow={"/images/glow.png"}
        altImages="Fenix Funeral Directors"
      >
        <HeroContentHeading
          headingTitle={aboutPageData.title}
          headingContent=""
          advisorData={advisor}
        />
        <Block4text8image
          sectionClass=" "
          h2={aboutPageData.titleAboutFenix}
          text={pageTextAboutFenix}
          bgImage="/images/285A6842-V2-klar_3.jpg"
        />
      </Hero>
      <Timeline/>
      <BenefitsSlider
        benefitSectionTitle={aboutPageData.titleWhyFenix}
        benefitSlider={aboutPageData.sliderWhyFenix}
      />
      <Section
        sectionClass={
          "bg-deepblue pl-4 pr-4 section-container section-container--loss pt-5"
        }
      >
        <Block8Right
          h2={aboutPageData.titleLineCurved}
          text={pageTextLineCurved}
          link="/"
          linkText="Read more"
        />
        <img
          src="/images/stripe_1.svg"
          alt="Fenix funeral directors"
          className="stripe stripe--1 stripe--about"
        />
      </Section>
      <Section sectionClass={"pl-4 pr-4 section-container"}>
        <BlockMap
          rowClass="row no-gutters flex-column-reverse flex-lg-row reverse-row"
          col1Class="col-12 col-lg-6 col-top-left bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5"
          col2Class="col-12 col-lg-6 col-bottom-right"
          leftSideClass="col-12 col-xl-6 col-top-left bg-white pl-3 pl-md-4 pr-3 pr-md-4 pt-4 pt-md-5 pb-4 pb-md-5"
          rightSideClass="col-12 col-xl-6 col-bottom-right"
          h2={aboutPageData.titleMap}
          text={pageTextMap}
          map={
            <MapSingle
              key={`fenix-map-single`}
              lat={51.509865}
              lng={-0.118092}
              hidePin={true}
            />
          }
          citySelect={
            <Popout
              key={`fenix-map-cityselect`}
              cities={useCityList()}
              id={"topbarSelectAboutFenix"}
            />
          }
        />
      </Section>
      <AdvisorRandom
        advisorImageBg={advisorBg?.edges}
        advisorData={advisor}
        sectionClassName="advisor-book advisor-book--section advisor-book--about pr-4 pl-4 pb-3"
      />
      <TrustpilotCarousel/>
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title="About us - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/about-fenix/"
      alternate="https://fenixfuneral.co.uk/about-fenix/"
      canonical="https://fenixfuneral.co.uk/about-fenix/"
      og={{url: "https://fenixfuneral.co.uk/about-fenix/"}}
    />
  );
}

export const pageQuery = graphql`
    fragment imageDataAboutBg on FileConnection {
        edges {
            node {
                name
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE
                        outputPixelDensities: 1
                    )
                }
            }
        }
    }
    query {
        advisorBg: allFile(filter: {name: {in: ["AdvisorBackgroundImage"]}}) {
            ...imageDataAboutBg
        }
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400,
                            height: 400,
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
        aboutPageData: strapiAboutUs {
            strapi_id
            title
            titleAboutFenix
            textAboutFenix {
                data {
                    textAboutFenix
                }
            }
            titleTimeline
            titleWhyFenix
            titleLineCurved
            textLineCurved {
                data {
                    textLineCurved
                }
            }
            titleMap
            textMap {
                data {
                    textMap
                }
            }
            sliderWhyFenix {
                title
                textMain
                icon {
                    url
                }
            }
        }
    }
`;