import * as React from "react";
import {useEffect} from "react";

export default function Timeline() {

  function scrollTimeline(e) {
    const timeLineProgress = document.getElementById("timeLineProgress");
    let timeLineRow = document.getElementById("timeLineRow");
    const timeProgressBar = document.getElementById("timeProgressBar");
    if (timeLineRow && timeProgressBar && timeLineProgress) {
      const percentage = e.target.scrollLeft / (1200 - (timeLineRow.offsetWidth));
      const leftPos = Math.round((timeLineProgress.offsetWidth - 88) * percentage);
      timeProgressBar.style.left = leftPos + "px";
    }
  }

  useEffect(() => {
    const timeProgress = document.getElementById("timeProgressBar");
    if (timeProgress) timeProgress.style.width = "88px";
    let timelineEl = document.getElementById("timeLineRow");
    timelineEl?.addEventListener("scroll", scrollTimeline);
    return function () {
      timelineEl?.removeEventListener("scroll", scrollTimeline);
    };
  }, []);

  return (
    <div className="timeline-block">
      <h2 className="timeline-block__header text-white text-left">Why we started Fenix</h2>
      <div id="timeLineRow" className="timeline-block__row">
        <svg id="timelineSvg" width="1440" height="550" viewBox="0 0 1440 550" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g mask="url(#mask0_1563_6984)">
            <path
              d="M337.455 346.305L336.41 344.6L337.455 346.305ZM415.571 298.413L414.577 296.677L414.551 296.692L414.526 296.708L415.571 298.413ZM467.627 268.614L466.633 266.878L467.627 268.614ZM1058.38 319.237L1057.49 317.446L1058.38 319.237ZM1128.3 284.5L1127.47 282.677L1127.44 282.692L1127.41 282.709L1128.3 284.5ZM1171.76 264.903L1172.58 266.726L1171.76 264.903ZM770.056 290.16L768.827 291.737L770.056 290.16ZM1440.1 236.959L1442.06 237.361L1442.86 233.442L1440.9 233.041L1440.1 236.959ZM1.28515 295.665L-0.0498056 294.176L-3.02832 296.846L-1.69336 298.335L1.28515 295.665ZM338.501 348.01L416.616 300.118L414.526 296.708L336.41 344.6L338.501 348.01ZM416.565 300.149L468.621 270.349L466.633 266.878L414.577 296.677L416.565 300.149ZM1059.27 321.028L1129.19 286.291L1127.41 282.709L1057.49 317.446L1059.27 321.028ZM1129.12 286.323L1172.58 266.726L1170.94 263.08L1127.47 282.677L1129.12 286.323ZM768.827 291.737C851.954 356.541 964.881 367.93 1059.27 321.028L1057.49 317.446C964.479 363.663 853.2 352.441 771.286 288.582L768.827 291.737ZM1172.58 266.726C1256.41 228.932 1350.01 218.516 1440.1 236.959L1440.9 233.041C1349.99 214.429 1255.53 224.939 1170.94 263.08L1172.58 266.726ZM771.286 288.582C683.552 220.187 563.178 211.612 466.633 266.878L468.621 270.349C563.756 215.89 682.373 224.34 768.827 291.737L771.286 288.582ZM336.41 344.6C227.54 411.347 86.5238 390.756 1.28515 295.665L-1.69336 298.335C84.8347 394.864 227.984 415.767 338.501 348.01L336.41 344.6Z"
              fill="#FFBBF4"/>
          </g>
          <circle cx="57.5" cy="344.5" r="17.5" fill="#FFBBF4"/>
          <text x="30" y="194" fill="#FFBBF4" className="timeline-block__title" fontWeight="400">2001</text>
          <text x="30" y="215" width="215" fill="#fff" className="timeline-block__text" fontWeight="400"
                fontSize="14px">
            <tspan x="30" y="215">Johannes’ mother passed</tspan>
            <tspan x="30" y="236">away and he realized just</tspan>
            <tspan x="30" y="257">how complicated things</tspan>
            <tspan x="30" y="278">are when arranging</tspan>
            <tspan x="30" y="297">a funeral.</tspan>
          </text>

          <circle cx="315.5" cy="358.5" r="17.5" fill="#FFBBF4"/>
          <text x="300" y="165" fill="#FFBBF4" className="timeline-block__title" fontWeight="400">2006</text>
          <text x="300" y="195" width="215" fill="#fff" className="timeline-block__text" fontWeight="400"
                fontSize="13px">
            <tspan x="300" y="195">Mikael examined the funeral</tspan>
            <tspan x="300" y="216">industry and found that</tspan>
            <tspan x="300" y="237">prices were high with</tspan>
            <tspan x="300" y="258">many hidden and</tspan>
            <tspan x="300" y="279">unexpected fees.</tspan>
          </text>

          <circle cx="533.5" cy="242.5" r="17.5" fill="#FFBBF4"/>
          <text x="517" y="322" fill="#FFBBF4" className="timeline-block__title" fontWeight="400">2009</text>
          <text x="517" y="165" width="350" fill="#fff" className="timeline-block__text" fontWeight="400"
                fontSize="13px">
            <tspan x="517" y="353">Charlotte investigated some</tspan>
            <tspan x="517" y="374">of the largest funeral</tspan>
            <tspan x="517" y="395">companies and consistently</tspan>
            <tspan x="517" y="416">noticed a surprising lack</tspan>
            <tspan x="517" y="437">of customer focus.</tspan>
          </text>

          <circle cx="791.5" cy="304.5" r="17.5" fill="#FFBBF4"/>
          <text x="775" y="142" fill="#FFBBF4" className="timeline-block__title" fontWeight="400">2014</text>
          <text x="775" y="173" width="215" fill="#fff" className="timeline-block__text" fontWeight="400"
                fontSize="13px">
            <tspan x="775" y="173">Johannes, Mikael and Charlotte</tspan>
            <tspan x="775" y="194">teamed up and decided to start</tspan>
            <tspan x="775" y="215">Fenix with the customer</tspan>
            <tspan x="775" y="236">as the focus point.</tspan>
          </text>

          <ellipse cx="1010.38" cy="337.5" rx="18.375" ry="17.5" fill="#FFBBF4"/>
          <text x="993" y="417" fill="#FFBBF4" className="timeline-block__title" fontWeight="400">2017</text>
          <text x="993" y="417" width="215" fill="#fff" className="timeline-block__text" fontWeight="400"
                fontSize="13px">
            <tspan x="993" y="438">Following successful growth</tspan>
            <tspan x="993" y="459">in Sweden, Fenix started</tspan>
            <tspan x="993" y="480">offering their services in</tspan>
            <tspan x="993" y="501">London. The company now</tspan>
            <tspan x="993" y="521">operates throughout the UK.</tspan>
          </text>

          <ellipse cx="1258.38" cy="238.5" rx="18.375" ry="17.5" fill="#FFBBF4"/>
          <text x="1242" y="318" fill="#FFBBF4" className="timeline-block__title" fontWeight="400">2022</text>
          <text x="1242" y="349" width="215" fill="#fff" className="timeline-block__text" fontWeight="400"
                fontSize="13px">
            <tspan x="1242" y="349">A complete rebrand of Fenix</tspan>
            <tspan x="1242" y="370">was introduced and Fenix is</tspan>
            <tspan x="1241" y="391">now focused on helping our</tspan>
            <tspan x="1242" y="412">customers through all aspects</tspan>
            <tspan x="1242" y="433">of life, including funeral</tspan>
            <tspan x="1242" y="454">planning and family law.</tspan>
          </text>
        </svg>
      </div>
      <div id="timeLineProgress" className="timeline-block__progress">
        <div className="timeline-block__progressbar" id="timeProgressBar"></div>
      </div>
    </div>
  );
}