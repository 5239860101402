import * as React from "react";
import {useState, Fragment} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';

export default function BenefitsSlider(props: any) {

  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null)
  return (
    <div className={props.sliderClass ? props.sliderClass : "bg-deepblue pl-0 pr-0 pl-sm-4 pr-sm-4 pt-lg-3 pt-2 overflow-hidden"}>
      <div className="why-block block-wrapper">
        <div className="row">
          <div className="col-12 col-lg-9">
            <h2
              className="why-block__header text-white pl-4 pr-4 pl-sm-0 pr-sm-0">{props.benefitSectionTitle ? props.benefitSectionTitle : "Why choose Fenix?"}</h2>
            {props.benefitSectionText  && <p className="why-block__text text-white pl-4 pr-4 pl-sm-0 pr-sm-0 pt-3 pb-3 pt-lg-4">{props.benefitSectionText}</p>}
          </div>
        </div>

        <div className="why-block__container swiper-container">
          <Swiper
            id="whyLineRow"
            modules={[Pagination]}
            pagination={{
              clickable: true,
              el: pagesEl
            }}
            className="why-block__row swiper-wrapper"
            grabCursor={true}
            breakpoints={{
              320: {
                slidesPerView: 1.4,
                spaceBetween: 15,
                keyboard: true,
                allowTouchMove: true,
                mousewheel: true,
                simulateTouch: true,
                slidesOffsetAfter: 25,
                slidesOffsetBefore: 25
              },
              719: {
                slidesPerView: 3,
                spaceBetween: 15,
                keyboard: false,
                allowTouchMove: false,
                mousewheel: false,
                simulateTouch: false,
                slidesOffsetAfter: 0,
                slidesOffsetBefore: 0
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
                keyboard: false,
                allowTouchMove: false,
                mousewheel: false,
                simulateTouch: false,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 15,
                keyboard: false,
                allowTouchMove: false,
                mousewheel: false,
                simulateTouch: false,
              },
            }}
          >
            {props.benefitSlider ? props.benefitSlider.map((item: any, index: any) => {
              return (
                <SwiperSlide key={index} className="why-block__column text-deepblue swiper-slide">
                  <div className="why-block__heading">
                    <div className="why-block__icon text-center">
                      <img src={item.icon?.url} alt="Fenix funeral directors" width="120" height="120"/>
                    </div>
                    <h4 className="why-block__title text-center">{item.title}</h4>
                  </div>
                  <div className="why-block__text text-center">{item.textMain}</div>
                </SwiperSlide>
              );
            }) : <Fragment>
              <SwiperSlide className="why-block__column text-deepblue swiper-slide">
                <div className="why-block__heading">
                  <div className="why-block__icon text-center">
                    <img src="/images/icons/icon_flowers.svg" alt="Fenix funeral directors" width="100" height="100"/>
                  </div>
                  <h4 className="why-block__title text-center">There by everyone’s side, every step of the way.</h4>
                </div>
                <div className="why-block__text text-center">No matter what challenging or complex moment of life or
                  loss
                  you’re facing, we’re here for you. Throughout the whole process, you can rely on us to make everything
                  more manageable.
                </div>
              </SwiperSlide>
              <SwiperSlide className="why-block__column text-deepblue swiper-slide">
                <div className="why-block__heading">
                  <div className="why-block__icon text-center">
                    <img src="/images/icons/icon_doc-deepblue.svg" alt="Fenix funeral directors" width="100"
                         height="100"/>
                  </div>
                  <h4 className="why-block__title text-center">Compassionate experts, plus the smartest digital
                    tools.</h4>
                </div>
                <div className="why-block__text text-center">Our warm and knowledgeable team is always here to support
                  you. We also offer useful technologies and tools that can make your journey as simple as possible.
                </div>
              </SwiperSlide>
              <SwiperSlide className="why-block__column text-deepblue swiper-slide">
                <div className="why-block__heading">
                  <div className="why-block__icon text-center">
                    <img src="/images/icons/icon_flower.svg" alt="Fenix funeral directors" width="100" height="100"/>
                  </div>
                  <h4 className="why-block__title text-center">Always transparent, always fair.</h4>
                </div>
                <div className="why-block__text text-center">We’re committed to the highest standards of honesty and
                  openness when it comes to fees, contracts, and terms. We have your best interests in mind, and will
                  never (ever) put pressure on you.
                </div>
              </SwiperSlide>
            </Fragment>}
          </Swiper>
          <div ref={(node) => setPagesEl(node)}
               className="swiper-pagination swiper-pagination--why swiper-pagination-clickable swiper-pagination-bullets"></div>
        </div>
      </div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  }
};