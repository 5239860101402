import * as React from "react";

export default function Block4text8image(props: any) {
  return (
    <div className={props.sectionClass ? props.sectionClass : "pl-4 pr-4 section-container"}>
      <div className="block-wrapper">
        <div className={props.rowClass ? props.rowClass : "row no-gutters flex-column-reverse flex-lg-row reverse-row"}>
          <div
            className={props.colFirstClass ? props.colFirstClass : "col-12 col-lg-4 col-top-left bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5"}>
            <h2 className="mb-4 text-deepblue">{props.h2}</h2>
            <div className="col-content text-deepblue">{props.text}</div>
            {props.link ?
              <a href={props.link} className="follow-link d-block mt-4 text-darkblue"><u>{props.linkText}</u></a> : ``}
          </div>
          <div className={props.colSecondClass ? props.colSecondClass : "col-12 col-lg-8 col-bottom-right col-bgImage"}
               style={{backgroundImage: `url(${props.bgImage})`}}></div>
        </div>
      </div>
    </div>
  );
}