import * as React from "react";

export default function Block8Right(props: any) {

  return (
    <div className="block-wrapper">
      <div className="row no-gutters">
        <div className="col-12 col-md-4 bg-transparent pl-0 pr-0 d-none d-md-block"></div>
        <div className=" col-12 col-md-8 pl-md-5 pr-md-5 pt-4 pt-md-5 pb-3 pb-md-5 bg-transparent"
             style={stylesInline.zindex}>
          <h2 className="mb-4 text-white">{props.h2}</h2>
          <div
            className="col-content text-white">{props.text ? props.text : "From loved ones dying to new births in the family, falling in love to separating after decades, careful future planning to entirely unexpected events – the only thing that’s inevitable about being human is that we all have to face the complexities of life & loss.<br /><br />No matter what challenging moment of life or loss you’re facing, our experience, services, technologies, and people are ready.<br /><br />From birth to death, the Fenix Family is there for your family, always."}</div>
        </div>
      </div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  zindex: {
    zIndex: 1,
  },
};