import * as React from "react";
import ClickReveal from "../click-reveal";
import {useFenixContext} from "../../helpers/context";

export default function BlockMap(props: any) {
  const {state, dispatch} = useFenixContext();
  return (
    <div className="block-wrapper">
      <div className={props.rowClass}>
        {props.h2 && <div className={props.col1Class}>
          <h2 className="mb-4 text-deepblue">{props.h2}</h2>
          <div
            className="col-content">{props.text ? props.text : "Not available to meet us in person? No problem. We’re also available for video meetings.<br/>We understand that things can be very overwhelming right now, and not everybody has the time or is in the right headspace to meet one of our experts in person.<br/>That’s why we are happy to announce that we now offer digital consultations so you can plan ahead in the comfort of your own home - or with your loved ones around you.<br/>Simply contact one of our local offices and one of our advisors will be more than happy to assist you with any queries you might have about the funeral process.<br/><br/>Click here to find the closest office to you."}</div>
          {props.headingTitle || props.office ? <div className="nap-info">
            {props.headingTitle && <b>{props.headingTitle}</b>}
            {props.office && <p className="mb-0">{props.office}</p>}
          </div> : ""}
          {!props.headingTitle && <div className='city-select city-select--blue mt-3'>
            {props.citySelect}
          </div>}
          {props.headingTitle && <a
            className=""
            data-toggle="modal"
            data-target="#reactForm"
            data-office-city-name={props.city}
            onClick={() => {
              dispatch({type: "modalBooking"});
            }}
          >
            Book meeting
          </a>}
          {props.headingTitle &&  ' | ' }
          {props.headingTitle && <ClickReveal
            className="ctr-button ctr-button--nap"
          />}

        </div>}
        <div className={props.col2Class} style={stylesInline.overflowHidden}>
          {props.map}
        </div>
      </div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  overflowHidden: {
    overflow: "hidden",
    backgroundColor: "#ffffff",
  }
};